// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  width: 100%;
  justify-content: center;
  display: flex;
  line-height: 1.4;
}

.page .button {
  font-size: var(--font-size-normal);
  padding: 0.2em 0.5em;
}

.pageInner {
  width: 100%;
  margin: 40px;
}

.pageInner > h1:first-child,
.pageInner > h2:first-child,
.pageInner > h3:first-child,
.pageInner > h4:first-child {
  margin-top: 0;
}

@media (min-width: 900px) {
  .pageInner {
    max-width: var(--page-max-width);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Page.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;;;EAIE,aAAa;AACf;;AAEA;EACE;IACE,gCAAgC;EAClC;AACF","sourcesContent":[".page {\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  line-height: 1.4;\n}\n\n.page .button {\n  font-size: var(--font-size-normal);\n  padding: 0.2em 0.5em;\n}\n\n.pageInner {\n  width: 100%;\n  margin: 40px;\n}\n\n.pageInner > h1:first-child,\n.pageInner > h2:first-child,\n.pageInner > h3:first-child,\n.pageInner > h4:first-child {\n  margin-top: 0;\n}\n\n@media (min-width: 900px) {\n  .pageInner {\n    max-width: var(--page-max-width);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
