// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewPDFSolo {
  justify-content: center;
  display: flex;
}

.viewPDFSoloWidth {
  max-width: 1000px;
}

.viewPDFSoloCanvas {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ViewPDF.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".viewPDFSolo {\n  justify-content: center;\n  display: flex;\n}\n\n.viewPDFSoloWidth {\n  max-width: 1000px;\n}\n\n.viewPDFSoloCanvas {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
