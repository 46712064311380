// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkDropboxContent {
  max-width: 500px;
  margin: 20px;
  line-height: 1.4;
}

.linkDropboxForm {
  display: flex;
}

.linkDropboxInput {
  flex: 1;
  padding: 7px;
  margin-inline-end: 10px;
}

.linkDropboxSubmit {
  padding: 7px;
}

.linkDropboxConnect {
  display: block;
  padding: 10px;
  margin: 2em 0px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
}

.linkDropboxConnect:hover {
  background-color: var(--primary-color-hover);
}

.linkDropbox {
  position: absolute;
  inset: var(--header-padding) 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkDropboxH1 {
  margin: 0;
}

.linkDropboxH2 {
  margin-top: 0;
  color: var(--text-border);
}

.linkDropboxAuth {
  animation: fadein 500ms;
}

@media (max-width: 1100px) {
  .linkDropboxEnd {
    width: 100%;
  }

  .linkDropboxEnd img {
    max-width: min(800px, calc(100% - var(--margin)));
    max-height: none;
    height: auto;
  }

  .linkDropboxEnd::before,
  .linkDropboxEnd::after {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkDropbox.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,OAAO;EACP,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,WAAW;EACX,sCAAsC;EACtC,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,iDAAiD;IACjD,gBAAgB;IAChB,YAAY;EACd;;EAEA;;IAEE,aAAa;EACf;AACF","sourcesContent":[".linkDropboxContent {\n  max-width: 500px;\n  margin: 20px;\n  line-height: 1.4;\n}\n\n.linkDropboxForm {\n  display: flex;\n}\n\n.linkDropboxInput {\n  flex: 1;\n  padding: 7px;\n  margin-inline-end: 10px;\n}\n\n.linkDropboxSubmit {\n  padding: 7px;\n}\n\n.linkDropboxConnect {\n  display: block;\n  padding: 10px;\n  margin: 2em 0px;\n  color: #fff;\n  background-color: var(--primary-color);\n  border-radius: 3px;\n  text-decoration: none;\n  text-align: center;\n}\n\n.linkDropboxConnect:hover {\n  background-color: var(--primary-color-hover);\n}\n\n.linkDropbox {\n  position: absolute;\n  inset: var(--header-padding) 0 0 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.linkDropboxH1 {\n  margin: 0;\n}\n\n.linkDropboxH2 {\n  margin-top: 0;\n  color: var(--text-border);\n}\n\n.linkDropboxAuth {\n  animation: fadein 500ms;\n}\n\n@media (max-width: 1100px) {\n  .linkDropboxEnd {\n    width: 100%;\n  }\n\n  .linkDropboxEnd img {\n    max-width: min(800px, calc(100% - var(--margin)));\n    max-height: none;\n    height: auto;\n  }\n\n  .linkDropboxEnd::before,\n  .linkDropboxEnd::after {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
