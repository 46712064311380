// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewImage {
  justify-content: center;
  align-items: center;
  display: flex;
  animation: fadein 200ms;
  height: calc(100vh - var(--header-padding))
}

.viewImage img {
  --image-padding: 30px;
  max-width: calc(100vw - var(--image-padding));
  max-height: calc(100vh - var(--image-padding) - var(--header-padding));
}
`, "",{"version":3,"sources":["webpack://./src/components/ViewImage.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB;AACF;;AAEA;EACE,qBAAqB;EACrB,6CAA6C;EAC7C,sEAAsE;AACxE","sourcesContent":[".viewImage {\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  animation: fadein 200ms;\n  height: calc(100vh - var(--header-padding))\n}\n\n.viewImage img {\n  --image-padding: 30px;\n  max-width: calc(100vw - var(--image-padding));\n  max-height: calc(100vh - var(--image-padding) - var(--header-padding));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
