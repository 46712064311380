// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textArea,
.textAreaMount {
  width: 100%;
  height: 100%;
}

.textAreaTextArea {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 0;
  outline: 0;
  padding: 10px;
  resize: none;
  font-family: 'Roboto Mono', monospace;
  font-size: var(--font-size-song);
  background: linear-gradient(270deg, #ddd7d3 0px, #f3efed 60px);
}

.textAreaDismiss {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  margin: 8px;
  padding: 0;
  border: 1px solid #aaa;
  border-radius: 8px;
  font-size: 0px;
  box-shadow: -1px 1px 3px #0003;
  /* Place this over the text area. */
  z-index: 1;
}

.textAreaDismiss::after,
.textAreaDismiss::before {
  content: "";
  height: 0;
  width: 18px;
  top: 13px;
  left: 5px;
  border-bottom: 2px solid #000;
  position: absolute;
  transform: rotate(45deg);
}

.textAreaDismiss::before {
  transform: rotate(-45deg);
}

.cm-wrap, .cm-editor { height: 100% }
.cm-scroller { overflow: auto }
.cm-content {
  padding-top: 20px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* padding bottom is handled by scrollPastEnd */
  font-size: 14px;
}
.cm-line {
  padding: 0 20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextArea.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,aAAa;EACb,YAAY;EACZ,qCAAqC;EACrC,gCAAgC;EAChC,8DAA8D;AAChE;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,WAAW;EACX,UAAU;EACV,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,8BAA8B;EAC9B,mCAAmC;EACnC,UAAU;AACZ;;AAEA;;EAEE,WAAW;EACX,SAAS;EACT,WAAW;EACX,SAAS;EACT,SAAS;EACT,6BAA6B;EAC7B,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,uBAAuB,aAAa;AACpC,eAAe,eAAe;AAC9B;EACE,4BAA4B;EAC5B,0BAA0B;EAC1B,2BAA2B;EAC3B,+CAA+C;EAC/C,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".textArea,\n.textAreaMount {\n  width: 100%;\n  height: 100%;\n}\n\n.textAreaTextArea {\n  width: calc(100% - 20px);\n  height: calc(100% - 20px);\n  border: 0;\n  outline: 0;\n  padding: 10px;\n  resize: none;\n  font-family: 'Roboto Mono', monospace;\n  font-size: var(--font-size-song);\n  background: linear-gradient(270deg, #ddd7d3 0px, #f3efed 60px);\n}\n\n.textAreaDismiss {\n  position: absolute;\n  right: 0;\n  width: 30px;\n  height: 30px;\n  margin: 8px;\n  padding: 0;\n  border: 1px solid #aaa;\n  border-radius: 8px;\n  font-size: 0px;\n  box-shadow: -1px 1px 3px #0003;\n  /* Place this over the text area. */\n  z-index: 1;\n}\n\n.textAreaDismiss::after,\n.textAreaDismiss::before {\n  content: \"\";\n  height: 0;\n  width: 18px;\n  top: 13px;\n  left: 5px;\n  border-bottom: 2px solid #000;\n  position: absolute;\n  transform: rotate(45deg);\n}\n\n.textAreaDismiss::before {\n  transform: rotate(-45deg);\n}\n\n.cm-wrap, .cm-editor { height: 100% }\n.cm-scroller { overflow: auto }\n.cm-content {\n  padding-top: 20px !important;\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n  /* padding bottom is handled by scrollPastEnd */\n  font-size: 14px;\n}\n.cm-line {\n  padding: 0 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
