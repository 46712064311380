// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addFileMenuError {
  margin: 8px;
  background: #ffbfbf;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #f00;
  font-size: var(--font-size-small);
}

.addFileMenuInput {
  flex: 1;
  font-size: var(--font-size-small);
}

.addFileMenuForm {
  display: flex;
  gap: 8px;
  margin: 8px;
  max-width: 300px;
}

.button.addFileMenuButton {
  margin: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AddFileMenu.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,OAAO;EACP,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".addFileMenuError {\n  margin: 8px;\n  background: #ffbfbf;\n  padding: 8px;\n  border-radius: 3px;\n  border: 1px solid #f00;\n  font-size: var(--font-size-small);\n}\n\n.addFileMenuInput {\n  flex: 1;\n  font-size: var(--font-size-small);\n}\n\n.addFileMenuForm {\n  display: flex;\n  gap: 8px;\n  margin: 8px;\n  max-width: 300px;\n}\n\n.button.addFileMenuButton {\n  margin: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
