// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nextPrev {
  display: none;
}

@media (hover: hover) {
  .nextPrev {
    touch-action: none;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    width: var(--page-padding);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 300ms;
    background: linear-gradient(var(--rotation), #004eca55 0%, #004eca00 100%);
  }

  .nextPrev:hover,
  .nextPrev:focus {
    opacity: 1;
    background: linear-gradient(var(--rotation), #004eca55 0%, #004eca00 100%);
  }
}

@media (max-width: 500px) {
  .nextPrev {
    display: none;
  }
}

@media (min-width: 1200px) {
  .nextPrev {
    width: calc(
      (50% - (var(--page-max-width) / 2) - var(--page-padding)) / 2
    );
  }
}

.nextPrevNext {
  right: 0;
  --rotation: 270deg;
}

.nextPrevBack {
  left: 0;
  --rotation: 90deg;
}

.nextPrev::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc();
  border: 2px solid #000;
  border-right: none;
  border-top: none;
}

.nextPrevBack::after {
  transform: translateX(7px) rotate(45deg);
}

.nextPrevNext::after {
  transform: translateX(-7px) rotate(-135deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/NextPrev.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,SAAS;IACT,UAAU;IACV,eAAe;IACf,MAAM;IACN,SAAS;IACT,0BAA0B;IAC1B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,UAAU;IACV,yBAAyB;IACzB,0EAA0E;EAC5E;;EAEA;;IAEE,UAAU;IACV,0EAA0E;EAC5E;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE;;KAEC;EACH;AACF;;AAEA;EACE,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,OAAO;EACP,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".nextPrev {\n  display: none;\n}\n\n@media (hover: hover) {\n  .nextPrev {\n    touch-action: none;\n    background: none;\n    border: none;\n    margin: 0;\n    padding: 0;\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    width: var(--page-padding);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    opacity: 0;\n    transition: opacity 300ms;\n    background: linear-gradient(var(--rotation), #004eca55 0%, #004eca00 100%);\n  }\n\n  .nextPrev:hover,\n  .nextPrev:focus {\n    opacity: 1;\n    background: linear-gradient(var(--rotation), #004eca55 0%, #004eca00 100%);\n  }\n}\n\n@media (max-width: 500px) {\n  .nextPrev {\n    display: none;\n  }\n}\n\n@media (min-width: 1200px) {\n  .nextPrev {\n    width: calc(\n      (50% - (var(--page-max-width) / 2) - var(--page-padding)) / 2\n    );\n  }\n}\n\n.nextPrevNext {\n  right: 0;\n  --rotation: 270deg;\n}\n\n.nextPrevBack {\n  left: 0;\n  --rotation: 90deg;\n}\n\n.nextPrev::after {\n  content: \"\";\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  top: calc();\n  border: 2px solid #000;\n  border-right: none;\n  border-top: none;\n}\n\n.nextPrevBack::after {\n  transform: translateX(7px) rotate(45deg);\n}\n\n.nextPrevNext::after {\n  transform: translateX(-7px) rotate(-135deg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
