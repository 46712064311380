// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: flex-end;
  z-index: 1; /* stack above appView */
}

.messagesMessage {
  padding: 8px 14px;
  background-color: #bed7ff;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 7px;
  animation: fadein 500ms;
}

.messagesMessage button {
  background: #fff;
  display: inline-block;
  margin-inline-start: 14px;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: var(--font-size-small);
  border: 1px solid #8c8c8c;
}

.messagesMessage button:hover {
  background-color: #eee;
}
`, "",{"version":3,"sources":["webpack://./src/components/Messages.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,qBAAqB;EACrB,UAAU,EAAE,wBAAwB;AACtC;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".messages {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column-reverse;\n  width: 100%;\n  align-items: flex-end;\n  z-index: 1; /* stack above appView */\n}\n\n.messagesMessage {\n  padding: 8px 14px;\n  background-color: #bed7ff;\n  border: 1px solid #000;\n  border-radius: 3px;\n  margin: 7px;\n  animation: fadein 500ms;\n}\n\n.messagesMessage button {\n  background: #fff;\n  display: inline-block;\n  margin-inline-start: 14px;\n  border-radius: 3px;\n  padding: 4px 10px;\n  font-size: var(--font-size-small);\n  border: 1px solid #8c8c8c;\n}\n\n.messagesMessage button:hover {\n  background-color: #eee;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
