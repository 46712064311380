// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splitterSolo,
.splitterSplit {
  /* Below the menu */
  z-index: 0;
  position: relative;
}

.splitterSplit {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  animation: fadein 200ms;
}

.splitterSplit > * {
  position: absolute;
  top: 0;
  height: 100%;
}

.splitterSplitMiddle {
  cursor: grab;
  z-index: 1;
  display: flex;
}

.splitterSplitMiddle.dragging {
  cursor: grabbing;
}

.splitterSplitMiddleVisible {
  height: 100%;
  background: #bbb;
  flex: 1;
}

.splitterSplitMiddle:hover {
  background: linear-gradient(90deg, transparent 0%, #fff 50%, transparent 100%);
}

.splitterSplitMiddle:hover .splitterSplitMiddleVisible {
  background: #aaa;
}

.splitterSplitStart {
  width: calc(50% - 0px);
  left: 0;
}

.splitterSplitEnd {
  right: 0;
  overflow: auto;
}

@media print {
  /* Do not print the splitter */
  .splitterSplitStart,
  .splitterSplitMiddle {
    display: none;
  }

  .splitterSplitEnd {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Splitter.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,OAAO;AACT;;AAEA;EACE,8EAA8E;AAChF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,QAAQ;EACR,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B;;IAEE,aAAa;EACf;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".splitterSolo,\n.splitterSplit {\n  /* Below the menu */\n  z-index: 0;\n  position: relative;\n}\n\n.splitterSplit {\n  height: 100%;\n  width: 100%;\n  position: relative;\n  overflow: hidden;\n  animation: fadein 200ms;\n}\n\n.splitterSplit > * {\n  position: absolute;\n  top: 0;\n  height: 100%;\n}\n\n.splitterSplitMiddle {\n  cursor: grab;\n  z-index: 1;\n  display: flex;\n}\n\n.splitterSplitMiddle.dragging {\n  cursor: grabbing;\n}\n\n.splitterSplitMiddleVisible {\n  height: 100%;\n  background: #bbb;\n  flex: 1;\n}\n\n.splitterSplitMiddle:hover {\n  background: linear-gradient(90deg, transparent 0%, #fff 50%, transparent 100%);\n}\n\n.splitterSplitMiddle:hover .splitterSplitMiddleVisible {\n  background: #aaa;\n}\n\n.splitterSplitStart {\n  width: calc(50% - 0px);\n  left: 0;\n}\n\n.splitterSplitEnd {\n  right: 0;\n  overflow: auto;\n}\n\n@media print {\n  /* Do not print the splitter */\n  .splitterSplitStart,\n  .splitterSplitMiddle {\n    display: none;\n  }\n\n  .splitterSplitEnd {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
