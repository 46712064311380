// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lcHeader {
  width: 100%;
  height: var(--lc-header-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background-color: #545cfb;
  color: #fff;
  z-index: 0;
  box-shadow: 0 0 50px rgba(var(--lc-accent-color-rgb), 0.2);
  gap: 10px;
  padding: 0 8px;
}

.lcHeaderLinks {
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: stretch;
}

a.lcHeaderLink {
  text-decoration: none;
  color: #fff;
  padding: 0 20px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &.active {
    color: var(--lc-accent-color);
    line-height: 14px;
    
    &::before {
      content: "";
      position: absolute;
      inset: 11px 5px;
      background-color: #fff;
      border-radius: 7px;
      z-index: -1;
    }

    .lcHeaderBubble {
      background-color: var(--lc-accent-color);
      color: #fff;
    }
  }
}


.lcHeaderBubble {
  background-color: #fff;
  border-radius: 12px;
  margin-inline-start: 5px;
  color: var(--lc-accent-color);
  padding: 5px;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LanguageCoach/LCHeader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,UAAU;EACV,0DAA0D;EAC1D,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;;EAEnB;IACE,6BAA6B;IAC7B,iBAAiB;;IAEjB;MACE,WAAW;MACX,kBAAkB;MAClB,eAAe;MACf,sBAAsB;MACtB,kBAAkB;MAClB,WAAW;IACb;;IAEA;MACE,wCAAwC;MACxC,WAAW;IACb;EACF;AACF;;;AAGA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,wBAAwB;EACxB,6BAA6B;EAC7B,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".lcHeader {\n  width: 100%;\n  height: var(--lc-header-height);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  box-sizing: border-box;\n  position: relative;\n  background-color: #545cfb;\n  color: #fff;\n  z-index: 0;\n  box-shadow: 0 0 50px rgba(var(--lc-accent-color-rgb), 0.2);\n  gap: 10px;\n  padding: 0 8px;\n}\n\n.lcHeaderLinks {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-self: stretch;\n}\n\na.lcHeaderLink {\n  text-decoration: none;\n  color: #fff;\n  padding: 0 20px;\n  position: relative;\n  height: 100%;\n  display: flex;\n  align-items: center;\n\n  &.active {\n    color: var(--lc-accent-color);\n    line-height: 14px;\n    \n    &::before {\n      content: \"\";\n      position: absolute;\n      inset: 11px 5px;\n      background-color: #fff;\n      border-radius: 7px;\n      z-index: -1;\n    }\n\n    .lcHeaderBubble {\n      background-color: var(--lc-accent-color);\n      color: #fff;\n    }\n  }\n}\n\n\n.lcHeaderBubble {\n  background-color: #fff;\n  border-radius: 12px;\n  margin-inline-start: 5px;\n  color: var(--lc-accent-color);\n  padding: 5px;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
