// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lcLearned {
  max-width: var(--lc-content-width);
  margin: var(--lc-margin-big) auto;
  line-height: var(--lc-line-height);
}

.lcLearnedIntro {
  /* This is only to match the margin collapse behavior of other panels. */
  display: flex;
}

.lcLearnedTextArea {
  width: 100%;
  height: 75vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/LanguageCoach/Learned.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,iCAAiC;EACjC,kCAAkC;AACpC;;AAEA;EACE,wEAAwE;EACxE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".lcLearned {\n  max-width: var(--lc-content-width);\n  margin: var(--lc-margin-big) auto;\n  line-height: var(--lc-line-height);\n}\n\n.lcLearnedIntro {\n  /* This is only to match the margin collapse behavior of other panels. */\n  display: flex;\n}\n\n.lcLearnedTextArea {\n  width: 100%;\n  height: 75vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
